import { Scene } from '@babylonjs/core/scene';

import { Engine } from '@babylonjs/core/Engines/engine';
import { ShadowGenerator } from '@babylonjs/core/Lights/Shadows';
import { ScreenshotTools } from '@babylonjs/core/Misc/screenshotTools';
import { Tools } from '@babylonjs/core/Misc/tools';

import { Tags } from '@babylonjs/core/Misc/tags';
import { Logger } from '@babylonjs/core/Misc/logger';
import { AssetsManager } from '@babylonjs/core/Misc/assetsManager';

import { PointerEventTypes } from '@babylonjs/core/Events/pointerEvents'

import { Texture } from '@babylonjs/core/Materials/Textures/texture';
import { CubeTexture } from '@babylonjs/core/Materials/Textures';
import { RenderTargetTexture } from '@babylonjs/core/Materials/Textures/renderTargetTexture';

import { PBRMaterial } from '@babylonjs/core/Materials/PBR/pbrMaterial';
import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import { Color3 } from '@babylonjs/core/Maths/math.color';
import { ArcRotateCamera } from '@babylonjs/core/Cameras/arcRotateCamera';

import '@babylonjs/core/Meshes/meshBuilder';
import '@babylonjs/core/Meshes/Builders/boxBuilder';
import '@babylonjs/loaders/glTF';

import { ShadowOnlyMaterial } from '@babylonjs/materials/shadowOnly/shadowOnlyMaterial';

import { AdvancedDynamicTexture } from '@babylonjs/gui/2D/advancedDynamicTexture';
import { Image } from '@babylonjs/gui/2D/controls/image';
import { Button } from '@babylonjs/gui/2D/controls/button';
import { StackPanel } from '@babylonjs/gui/2D/controls/stackPanel';
import { Control } from '@babylonjs/gui/2D/controls/control';

import logo_360 from "./logo_360-icon.png";
import bright_env_360 from "./bright.env";
import product_data_json from "./207.json";

import isMobile from 'ismobilejs';

export default class{

	onCreate() {

		this.scene = null
		this.advanced_texture = null;
		this.render_on_movement = false;
		this.assetsManager = null;
		this.parent_children_dependencies = {};
		this.child_parents_dependencies = {};
		this.W360 = {camera_calls: 0};
		this.hdr_textures = {};

		this.state = {
			product: {},
			product_id: 0,
			owner_id: 1,
			canvas_id: 'tridentics-viewer',
			background_color: '#ffffff',
			canvas_container_height: '100%',
			canvas_container_width: '60%',
			option_container_width: '40%',
			option_container_height: '100%',
			swatch_height: '50px',
			swatch_width: '50px',
			layout: 0,
			is_first_call: true,
			is_first_action: true,
			show_overlay: false,
			base64_image: ''
		}
	}

	onInput(input) {
		this.state.canvas_id = 'tridentics-viewer';
		if (input.hasOwnProperty('product_id')) {
			this.state.product_id = input.product_id;
		}
		if (input.hasOwnProperty('owner_id')) {
			this.state.owner_id = input.owner_id;
		}
		if (input.hasOwnProperty('canvas_id')) {
			this.state.canvas_id = input.canvas_id;
		}
		if (input.hasOwnProperty('background_color')) {
			this.state.background_color = input.background_color;
		}
		if (input.hasOwnProperty('swatch_height')) {
			this.state.swatch_height = input.swatch_height;
		}
		if (input.hasOwnProperty('swatch_width')) {
			this.state.swatch_width = input.swatch_width;
		}
		if (input.hasOwnProperty('option_container_width')) {
			this.state.option_container_width = input.option_container_width;
		}
		if (input.hasOwnProperty('canvas_container_height')) {
			this.state.canvas_container_height = input.canvas_container_height;
		}
		if (input.hasOwnProperty('canvas_container_width')) {
			this.state.canvas_container_width = input.canvas_container_width;
		}
	}

	onMount() {
		const ctx = this;
		for (const key in product_data_json['option_values']) {
			if (Object.hasOwnProperty.call(product_data_json['option_values'], key)) {
				const option = product_data_json['option_values'][key];
				let has_active = false
				for (const key2 in option) {
					if (Object.hasOwnProperty.call(option, key2)) {
						const value = option[key2];
						if (Object.hasOwnProperty.call(value, "default")) {
							product_data_json['option_values'][key][key2]["active"] = 0;
							if (value["default"] === 1) {
								has_active = true;
								product_data_json['option_values'][key][key2]["active"] = 1;
							}
						}

						if (Object.hasOwnProperty.call(value, "dependent_ids")) {
							if (value["dependent_ids"].length > 0) {
								ctx.parent_children_dependencies[value["option_type_id"]] = value["dependent_ids"];
							}
						}
					}
				}
				/*force first item as active.*/
				if (has_active === false) {
					product_data_json['option_values'][key][0]["active"] = 1;
				}
			}
		}
		/*Reverse keys (children to parent ids)*/
		for (const parent_option_type_id in ctx.parent_children_dependencies) {
			if (Object.hasOwnProperty.call(ctx.parent_children_dependencies, parent_option_type_id)) {
				const children = ctx.parent_children_dependencies[parent_option_type_id];
				children.forEach(child_option_type_id => {
					if (!Object.hasOwnProperty.call(ctx.child_parents_dependencies, child_option_type_id)) {
						ctx.child_parents_dependencies[child_option_type_id] = [];
					}
					ctx.child_parents_dependencies[child_option_type_id].push(parent_option_type_id);
				});
			}
		}
		ctx.state.product = product_data_json;
		ctx.setStateDirty("product");
		this.buildViewer();
	}

	onUpdate() { }

	inArray(needle, haystack) {
		const { length } = haystack;
		for (let i = 0; i < length; i++) {
			if (haystack[i] == needle) return true;
		}
		return false;
	};

	isActiveOption(option, active_option_type_ids) {
		if (option.is_dependent === 0) {
			return true;
		}
		/*OR dependency. If one option value is selected load the option*/
		for (const key in this.state.product['option_values']) {
			if (key == option.option_id) {
				if (Object.hasOwnProperty.call(this.state.product['option_values'], key)) {
					const option = this.state.product['option_values'][key];
					for (const key2 in option) {
						if (Object.hasOwnProperty.call(option, key2)) {
							const option_type_value = option[key2];
							for (let index = 0; index < active_option_type_ids.length; index++) {
								const active_option_type_id = active_option_type_ids[index];
								if (this.inArray(active_option_type_id, this.child_parents_dependencies[option_type_value.option_type_id])) {
									return true;
								}
							};
						}
					}
				}
			}
		}
		return false;
	}

	isActiveOptionValue(option, option_type_id, active_option_type_ids) {
		if (option.is_dependent === 0) {
			return true;
		}
		/*OR dependency. If one option value is selected load the option*/
		for (const key in this.state.product['option_values']) {
			if (key == option.option_id) {
				if (Object.hasOwnProperty.call(this.state.product['option_values'], key)) {
					const option = this.state.product['option_values'][key];
					for (const key2 in option) {
						if (Object.hasOwnProperty.call(option, key2)) {
							const option_type_value = option[key2];
							if (option_type_id == option_type_value.option_type_id) {
								for (let index = 0; index < active_option_type_ids.length; index++) {
									const active_option_type_id = active_option_type_ids[index];
									if (this.inArray(active_option_type_id, this.child_parents_dependencies[option_type_value.option_type_id])) {
										return true;
									}
								};
								return false;
							}
						}
					}
				}
			}
		}
		return false;
	}

	onSelectionChange(option_id, evt) {

		evt.preventDefault();
		const selected = parseInt(evt.srcElement.value);
		for (const key in this.state.product['option_values']) {
			if (key == option_id) {
				if (Object.hasOwnProperty.call(this.state.product['option_values'], key)) {
					const option = this.state.product['option_values'][key];
					for (const key2 in option) {
						if (Object.hasOwnProperty.call(option, key2)) {
							const value = option[key2];
							if (Object.hasOwnProperty.call(value, "default")) {
								this.state.product['option_values'][key][key2]["active"] = 0;
								if (value["option_type_id"] === selected) {
									this.state.product['option_values'][key][key2]["active"] = 1;
								}
							}
						}
					}
				}
			}
		}
		this.setStateDirty("product");
	}

	onClickChange(option_id, option_type_id, evt) {
		if (evt) {
			evt.preventDefault();
		}

		const selected = option_type_id;
		for (const key in this.state.product['option_values']) {
			if (key == option_id) {
				if (Object.hasOwnProperty.call(this.state.product['option_values'], key)) {
					const option = this.state.product['option_values'][key];
					for (const key2 in option) {
						if (Object.hasOwnProperty.call(option, key2)) {
							const value = option[key2];
							if (Object.hasOwnProperty.call(value, "default")) {
								this.state.product['option_values'][key][key2]["active"] = 0;
								if (value["option_type_id"] === selected) {
									this.state.product['option_values'][key][key2]["active"] = 1;
								}
							}
						}
					}
				}
			}
		}

		this.state.is_first_action = false;
		this.setStateDirty("product");
	}

	calculatePrice(active_option_type_ids) {

		/* Base price*/
		let highestBaseObject = null;
		let highestBaseCounter = 0;
		this.state.product['price_combis'].forEach(price_combi => {
			let counter = 0;
			if (price_combi["rule"] === "base") {
				active_option_type_ids.forEach(active_option_type_id => {
					if (this.inArray(active_option_type_id, price_combi["option_values"])) {
						counter++;
					}
				});
			}
			if (highestBaseCounter < counter) {
				highestBaseCounter = counter;
				highestBaseObject = price_combi;
			}
			else if (highestBaseCounter === counter) {
				if (highestBaseObject !== null) {
					if (parseInt(price_combi["prioriy"]) < parseInt(highestBaseObject["prioriy"])) {
						highestBaseCounter = counter;
						highestBaseObject = price_combi;
					}
				}
			};
		});

		let basePrice = (highestBaseObject !== null) ? highestBaseObject['price'] : 0.0000;
		let addonPrice = 0.0000;

		/* Addon price*/
		this.state.product['price_combis'].forEach(price_combi => {
			if (price_combi["rule"] === "addon") {
				let counter = 0;
				let total = price_combi["option_values"].length;
				active_option_type_ids.forEach(active_option_type_id => {
					if (this.inArray(active_option_type_id, price_combi["option_values"])) {
						counter++;
					}
				});

				if (counter === total) {
					addonPrice += price_combi['price'];
				}
			}
		});
		return parseFloat(basePrice) + parseFloat(addonPrice);
	}

	isEmpty(obj) {
		for (let prop in obj) {
			if (obj.hasOwnProperty(prop)) {
				return false;
			}
		}
		return JSON.stringify(obj) === JSON.stringify({});
	}

	buildViewer() {
		Logger.LogLevels = 0;
		const ctx = this;

		const canvas = document.getElementById(this.state.canvas_id);
		const engine = new Engine(canvas, true, { preserveDrawingBuffer: true, stencil: true });

		engine.enableOfflineSupport = false;
		engine.renderEvenInBackground = true;
		engine.disableManifestCheck = true;
		engine.disablePerformanceMonitorInBackground = true;

		this.scene = new Scene(engine);

		/*Build GUI*/
		this.advanced_texture = AdvancedDynamicTexture.CreateFullscreenUI("UI");

		const stack_panel = new StackPanel("branding");
		stack_panel.isVertical = false;
		stack_panel.heightInPixels = 72;
		stack_panel.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;
		stack_panel.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
		stack_panel.metadata = {};
		stack_panel.metadata.counter = 0;
		this.advanced_texture.addControl(stack_panel);

		const logo_button = new Image("logo", `${logo_360}`);
		logo_button.widthInPixels = 72;
		logo_button.heightInPixels = 72;
		stack_panel.addControl(logo_button);

		const text_description = (isMobile(window.navigator).any === true) ? 'Swipe to rotate. Pinch to zoom.' : 'Drag to rotate. Scroll to zoom.';
		const text = Button.CreateSimpleButton("but", text_description);
		text.fontSize = 20;
		text.thickness = 0;
		text.background = "transparent";
		text.widthInPixels = 300;
		text.heightInPixels = 72;
		

		stack_panel.addControl(text);	

		/*Add HDR*/
		if (this.hdr_textures['default'] == null) {
			this.hdr_textures['default'] = new CubeTexture(`${bright_env_360}`, this.scene);
			this.scene.environmentTexture = this.hdr_textures['default'];
			this.scene.environmentTexture.level = 1;
			this.scene.environmentTexture.gammaSpace = false;
			this.scene.environmentIntensity = 0.77;
		}

		this.scene.clearColor = Color3.FromHexString(this.state.background_color);

		this.scene.physicsEnabled = false
		this.scene.shadowsEnabled = false;
		this.scene.lightsEnabled = true;
		this.scene.particlesEnabled = false;
		this.scene.spritesEnabled = false;
		this.scene.skeletonsEnabled = false;
		this.scene.lensFlaresEnabled = false;
		this.scene.collisionsEnabled = false;
		this.scene.workerCollisions = false;
		this.scene.probesEnabled = false;
		this.scene.audioEnabled = false;
		this.scene.animationsEnabled = false;
		this.scene.postProcessesEnabled = false;

		this.scene.imageProcessingConfiguration.contrast = 1;
		this.scene.imageProcessingConfiguration.exposure = 1;
		this.scene.imageProcessingConfiguration.toneMappingEnabled = false;
		this.scene.imageProcessingConfiguration.applyByPostProcess = false;
		this.scene.imageProcessingConfiguration.isEnabled = false;

		let camera = new ArcRotateCamera('Camera', 1, 0.8280158181074566, 12, new Vector3(0, 0, 0), this.scene);
		camera.attachControl(canvas, true);
		camera.lowerRadiusLimit = 5;
		camera.upperRadiusLimit = 70;
		camera.minZ = 0.01;
		camera.checkCollisions = false;
		camera.allowUpsideDown = false;
		camera.wheelPrecision = 50;
		camera.alpha = -1;
		camera.beta = 1.28;
		camera.upperBetaLimit = 1.57

		this.scene.activeCamera = camera;

		this.scene.onPointerObservable.add((pointerInfo) => {
			if (ctx.scene.isReady() === false) { return; }
			
			if (pointerInfo.type === PointerEventTypes.POINTERDOWN || pointerInfo.type === PointerEventTypes.POINTERTAP) {			
				if(stack_panel){
					stack_panel.metadata.counter++;
					if(stack_panel.metadata.counter === 3){
						stack_panel.isVisible = false;
					}
				}						
			}		

			if (pointerInfo.type === PointerEventTypes.POINTERPICK) {
				if (pointerInfo.pickInfo.pickedMesh.id === 'floor') { return; }
				ctx.scene.activeCamera.target = pointerInfo.pickInfo.pickedPoint.clone();
				ctx.scene.render();
			}
		});

		engine.runRenderLoop(function () {

			if(ctx.scene.isReady()){
				if(ctx.render_on_movement === false){
					ctx.render_on_movement = true;
					ctx.scene.render();				
				}else if (Math.abs(ctx.scene.activeCamera.inertialRadiusOffset) > 0 ||
					Math.abs(ctx.scene.activeCamera.inertialAlphaOffset) > 0 ||
					Math.abs(ctx.scene.activeCamera.inertialBetaOffset) > 0) {
					ctx.scene.render();
				}
			}else{
				ctx.render_on_movement = false;
			}
			
		});
		window.addEventListener('resize', function () {
			ctx.scene.getEngine().resize();
			ctx.scene.render();
		});

		window.onbeforeunload = function (e) {
			for (let i = ctx.scene.meshes.length - 1; i >= 0; i--) {
				if (ctx.scene.meshes[i].name != "parent") {
					ctx.scene.meshes[i].dispose();
				}
			}

			for (let i = ctx.scene.meshes.length - 1; i >= 0; i--) {
				ctx.scene.meshes[i].dispose();
			}

			ctx.scene.dispose();
			e = null; //setting e to null allows the refresh
		}
	}

	updateMaterials(options) {

		const ctx = this;

		if (this.scene.getMeshByName('workspace_bottom') === null) { return; }
		if (this.scene.getMeshByName('workspace_top') === null) { return; }
		if (this.scene.getMeshByName('panel_1') === null) { return; }

		let basepath = 'https://thinkingspace.360productviewer.com/assets/textures/';
		basepath += (isMobile(window.navigator).any === true) ? 'mobile/' : '';

		const workspace_bottom_material = this.scene.getMeshByName('workspace_bottom').material;
		const workspace_top_material = this.scene.getMeshByName('workspace_top').material;
		workspace_top_material.albedoColor.r = 1;
		workspace_top_material.albedoColor.g = 1;
		workspace_top_material.albedoColor.b = 1;
		workspace_top_material.roughness = 0.88;
		workspace_top_material.metallic = 0.22;

		const panel_material = this.scene.getMeshByName('panel_1').material;
		panel_material.roughness = 0.88;
		panel_material.metallic = 0.22;

		/*workspace_bottom*/
		switch (options[1]) {
			case 15309:
				workspace_bottom_material.albedoColor.r = 0;
				workspace_bottom_material.albedoColor.g = 0;
				workspace_bottom_material.albedoColor.b = 0;
				break;
			case 15310:
				workspace_bottom_material.albedoColor.r = 40 / 255;
				workspace_bottom_material.albedoColor.g = 40 / 255;
				workspace_bottom_material.albedoColor.b = 40 / 255;
				break;
			default:
				break;
		}

		/*panel_material*/
		panel_material.albedoTexture = new Texture(`${basepath}${options[3]}.jpg`, this.scene);

		/*workspace_top*/
		workspace_top_material.albedoTexture = new Texture(`${basepath}${options[5]}.jpg`, this.scene);

		switch (options[5]) {
			case 15345:
			case 15348:
			case 15349:
			case 15350:
			case 15351:	
			case 15352:
			case 15353:
			case 15354:
			case 15355:	
			case 15356:
			case 15357:
			case 15358:
			case 15359:	
			case 15360:		
			case 15362:
			case 15363:
			case 15364:	
			case 15365:
			case 15366:
			case 15367:
			case 15368:
			case 15369:
			case 15370:
			case 15371:
			case 15372:			
			case 15373:		
			case 15374:		
			case 15375:		
			case 15376:		
			case 15377:		
			case 15378:		
			case 15391:			
				workspace_top_material.albedoTexture.uScale = workspace_top_material.albedoTexture.vScale = 4;
				break;
			case 15346:
			case 15347:			
				workspace_top_material.albedoTexture.uScale = workspace_top_material.albedoTexture.vScale = 2;
				break;
			case 15361:			
				workspace_top_material.albedoTexture.uScale = workspace_top_material.albedoTexture.vScale = 8;
				break;			
			default:
				break;
		}

		this.scene.render();
	}

	updateViewer(options) {

		const ctx = this;

		this.state.is_first_call = false;

		const parameters = {
			opt: options,
			prid: this.state.product_id,
			owd: this.state.owner_id
		};
		let encodedString = '';
		for (let prop in parameters) {
			if (parameters.hasOwnProperty(prop)) {
				if (encodedString.length > 0) {
					encodedString += '&';
				}
				encodedString += encodeURI(prop + '=' + parameters[prop]);
			}
		};

		const endpoint = 'https://api.360productviewer.com/360api/native.php';
		const xhr = new XMLHttpRequest();
		xhr.open('GET', `${endpoint}?${encodedString}`);
		xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
		xhr.onload = function () {
			if (xhr.status === 200) {
				const json = JSON.parse(xhr.responseText);
				ctx.loadMeshes(json, options);
			} else if (xhr.status !== 200) { }
		}
		xhr.send(encodedString);
	}

	loadMeshes(response, options) {

		const ctx = this;

		if (this.assetsManager === null) {
			this.assetsManager = new AssetsManager(this.scene);
		}
		this.assetsManager.reset();
		this.assetsManager.useDefaultLoadingScreen = false;

		for (let i = this.scene.meshes.length - 1; i >= 0; i--) {
			this.scene.meshes[i].dispose();
		}

		for (let key in response) {
			if (!response.hasOwnProperty(key)) {
				continue;
			}

			if (response[key].type === "camera") {
				this.scene.activeCamera.alpha = parseFloat(response[key]['alpha']);
				this.scene.activeCamera.beta = parseFloat(response[key]['beta']);
				this.scene.activeCamera.radius = parseFloat(response[key]['radius']);

				const vec = JSON.parse(response[key]['lookat_xyz']);
				this.scene.activeCamera.target.x = vec[0];
				this.scene.activeCamera.target.y = vec[1];
				this.scene.activeCamera.target.z = vec[2];
				continue;
			}

			if (!response[key].hasOwnProperty("mesh_id"))
				continue;

			const meshTask = this.assetsManager.addMeshTask(key, "", response[key].mesh_file_url, "");
			meshTask["response"] = response[key];

			meshTask.onSuccess = function (task) {

				try {

					ctx.scene.lights[0].shadowMinZ = 0.01;
					ctx.scene.lights[0].shadowMaxZ = 100;				

					ctx.scene.lights[1].includedOnlyMeshes.push(ctx.scene.getMeshByID("panel_1"));
					ctx.scene.lights[2].includedOnlyMeshes.push(ctx.scene.getMeshByID("panel_1"));
					ctx.scene.lights[3].includedOnlyMeshes.push(ctx.scene.getMeshByID("panel_1"));

					ctx.scene.lights[3].intensity = ctx.scene.lights[2].intensity = ctx.scene.lights[1].intensity = 1.44;

					ctx.scene.lights[0].autoUpdateExtends = false;
					ctx.scene.lights[1].autoUpdateExtends = false;
					ctx.scene.lights[2].autoUpdateExtends = false;
					ctx.scene.lights[3].autoUpdateExtends = false;

					const is_mobile = (isMobile(window.navigator).any === true) ? true : false;
					const shadow_size = (is_mobile === true) ? 2048 : 1024;

					let shadowGenerator = new ShadowGenerator(shadow_size, ctx.scene.lights[0]);
					shadowGenerator.bias = 0.001;
					shadowGenerator.normalBias = 0.02;
					shadowGenerator.contactHardeningLightSizeUVRatio = 0.05;
					shadowGenerator.setDarkness(0.01);

					shadowGenerator.useContactHardeningShadow = !is_mobile;
					shadowGenerator.usePoissonSampling = false;
					shadowGenerator.usePercentageCloserFiltering = false;
					shadowGenerator.useExponentialShadowMap = false;
					shadowGenerator.useBlurExponentialShadowMap = false;
					shadowGenerator.useCloseExponentialShadowMap = false;
					shadowGenerator.useBlurCloseExponentialShadowMap = false;
					shadowGenerator.forceBackFacesOnly = false;
					shadowGenerator.blurKernel = 32;
					shadowGenerator.useKernelBlur = true;
					shadowGenerator.frustumEdgeFalloff = 1;


					for (let index = 0; index < task.loadedMeshes.length; index++) {
						const mesh = task.loadedMeshes[index];
						mesh.isPickable = false;
						mesh.isVisible = true;

						if (mesh.name !== 'floor') {
							shadowGenerator.getShadowMap().renderList.push(mesh);
						}
						if (mesh.isAnInstance === false) {
							mesh.receiveShadows = true;

							mesh.isPickable = true;
							mesh.freezeNormals();						
							mesh.freezeWorldMatrix();
							mesh.computeWorldMatrix(true);
						}

						Tags.AddTagsTo(mesh, 'live_element');
					}

					shadowGenerator.getShadowMap().refreshRate = RenderTargetTexture.REFRESHRATE_RENDER_ONCE;

					ctx.updateMaterials(options);

				} catch (err) {
					console.log(err);
				}
			}
		};

		this.assetsManager.onFinish = function (tasks) {

			if (tasks.length === 0) { return; }
			try {
				let mesh = ctx.scene.getMeshByName('floor');
				mesh.material = new ShadowOnlyMaterial('floorbox_shadow_material', ctx.scene);
				mesh.material.activeLight = ctx.scene.lights[0];
				mesh.material.shadowColor.r = 0.025;
				mesh.material.shadowColor.g = 0.025;
				mesh.material.shadowColor.b = 0.025;
				mesh.material.alpha = 0.1;

				mesh = ctx.scene.getMeshByName('doorpanel_1');
				const glass = new PBRMaterial("glass", ctx.scene);
				glass.reflectionTexture = ctx.scene.environmentTexture.clone();
				glass.indexOfRefraction = 0.52;
				glass.alpha = 0.9;
				glass.directIntensity = 0.0;
				glass.environmentIntensity = 0.7;
				glass.cameraExposure = 0.66;
				glass.cameraContrast = 1.66;
				glass.microSurface = 1;
				glass.reflectivityColor = new Color3(0.2, 0.2, 0.2);
				glass.albedoColor = new Color3(0, 0, 0);
				mesh.material = glass;

			} catch (err) {
				console.log('catch ' + err.message);
			}
		};

		this.assetsManager.load();
	}

	isDataComplete(data, key) {
		if (data.hasOwnProperty(key)) {
			if (typeof data[key] !== "undefined" && data[key] != "" && data[key] != null) {
				return true;
			}
		}
		return false;
	}

	setScale(mesh, x, y, z) {
		mesh.scaling.x = x;
		mesh.scaling.y = y;
		mesh.scaling.z = z;
	}

	setPosition(mesh, x, y, z) {
		mesh.position.x = x;
		mesh.position.y = y;
		mesh.position.z = z;
	}

	setRotation(mesh, x, y, z) {
		mesh.rotation.x = x;
		mesh.rotation.y = y;
		mesh.rotation.z = z;
	}

	getColorRGB(r, g, b) {
		return new Color3(r, g, b);
	}

	getTrueFalse(input) {
		if (input === 0) {
			return false;
		}

		if (input === 1) {
			return true;
		}
		return false;
	}

	updateMaterial(data, mesh) {

		if (isDataComplete(data, "material_id")) {

			for (let a = this.scene.materials.length - 1; 0 <= a; a--) {
				if (this.scene.materials[a].id === data.mc_id) {
					mesh.material = this.scene.materials[a];
					return;
				}
			}

			const mat = new PBRMaterial(data.mc_id + "_" + data.material_id + "_" + data.mesh_id, this.scene);
			mat.id = data.mc_id;
			mat.reflectionTexture = this.hdr_textures['default'].clone();
			mat.microSurface = 0;
			mat.backFaceCulling = true;

			const floatObjects = {
				emissiveIntensity: "emissive_intensity",
				directIntensity: "direct_intensity",
				directIntensity: "direct_intensity",
				environmentIntensity: "environment_intensity",
				microSurface: "micro_surface",
				cameraContrast: "camera_contrast",
				cameraExposure: "camera_exposure",
				alpha: "alpha"
			}

			for (let prop in floatObjects) {
				if (floatObjects.hasOwnProperty(prop)) {
					if (isDataComplete(data, floatObjects[prop])) {
						mat[prop] = parseFloat(data[floatObjects[prop]]);
					}
				}
			}

			const colorObjects = {
				albedoColor: "albedo_color",
				ambientColor: "ambient_color",
				emissiveColor: "emissive_color",
				reflectivityColor: "reflectivity_color",
				reflectionColor: "reflextion_color",
			}

			for (let prop in colorObjects) {
				if (colorObjects.hasOwnProperty(prop)) {
					if (isDataComplete(data, colorObjects[prop])) {
						let hash = data[colorObjects[prop]].split('#')[1];
						if (hash) {
							mat[prop] = new Color3.FromHexString(data[colorObjects[prop]]);
						} else {
							let rgb = JSON.parse(data[colorObjects[prop]]);
							mat[prop] = getColorRGB(rgb[0], rgb[1], rgb[2]);
						}
					}
				}
			}

			const texObjects = {
				albedoTexture: "albedo_texture",
				emissiveTexture: "emissive_texture",
				bumpTexture: "bump_texture",
				lightmapTexture: "lightmap_texture",
			}

			for (let prop in texObjects) {
				if (texObjects.hasOwnProperty(prop)) {
					if (isDataComplete(data, texObjects[prop])) {
						mat[prop] = new Texture(data[texObjects[prop]], this.scene);
						if (isDataComplete(data, texObjects[prop] + "_config")) {
							const options = JSON.parse('' + data[texObjects[prop] + "_config"]);
							mat[prop].uScale = options.u;
							mat[prop].vScale = options.v;
							mat[prop].level = options.l;
						}
					}
				}
			}

			const boolObjects = {
				useMicroSurfaceFromReflectivityMapAlpha: "use_micro_surface_from_reflectivity_map_alpha",
				unlit: "unlit"
			}

			for (let prop in boolObjects) {
				if (boolObjects.hasOwnProperty(prop)) {
					if (isDataComplete(data, boolObjects[prop])) {
						mat[prop] = getTrueFalse(data[boolObjects[prop]]);
					}
				}
			}

			mat.freeze();
			mesh.material = mat;
		}
	}

	toggleOverlay(evt) {
		const ctx = this;

		this.scene.activeCamera.target = new Vector3(0, 5, 0);
		this.scene.activeCamera.alpha = Math.PI / 2;
		this.scene.activeCamera.beta = 1.28;
		this.scene.activeCamera.zoomOnFactor = 0.8
		this.scene.activeCamera.zoomOn([this.scene.getMeshByName('workspace_top'), this.scene.getMeshByName('panel_1')], false);

		if(this.advanced_texture !== null){
			this.advanced_texture.dispose();
			this.advanced_texture = null;
		}

		this.scene.onReadyObservable.addOnce(() => {
			Tools.CreateScreenshotUsingRenderTarget(this.scene.getEngine(), this.scene.activeCamera, {precision: 1.0}, (data) => {
				ctx.state.show_overlay = true;
				ctx.state.base64_image = data;
			}, undefined, undefined, false, undefined, false, false, true, undefined, undefined);
		});

		this.scene.render();
	}

	handleOverlayHide() {
		this.state.show_overlay = !this.state.show_overlay;
	}

	handleOverlayCancel() {
		this.state.show_overlay = !this.state.show_overlay;
	}

	isMobileDevice() {
		if(window !== undefined){
			return isMobile(window.navigator).any;
		}
		
	}
}