import 'core-js/features/string/replace-all';

export default class{

  onCreate() {
    this.state = {
      width: '80%',
      height: '80%',
      visible: false,
      current_active_option_values: [],
      base64_image: ''
    };
  }

  onInput(input) {
    this.state.visible = input.visible;
    this.state.width = input.width;
    this.state.height = input.height;
    this.state.current_active_option_values = input.current_active_option_values;
    this.state.base64_image = input.base64_image;
  };

  handleCancelButtonClick() {
    this.emit('cancel', {});
  };

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  dataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(',');
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }
    return new Blob([ia], { type: mimeString });
  }

  sendConfigurationAsMail() {
  
    const field_email = document.getElementById('field_email');
    const field_name = document.getElementById('field_name');
    const field_project_id = document.getElementById('field_project_id');
    const field_company = document.getElementById('field_company');
    const field_email_response = document.getElementById('field_email_response');

    try {

      if(field_email === null){console.log('field_email not found');return;}
      if(field_name === null){console.log('field_name not found');return;}
      if(field_project_id === null){console.log('field_project_id not found');return;}
      if(field_company === null){console.log('field_company not found');return;}
      if(field_email_response === null){console.log('email_response not found');return;}

      if (this.validateEmail(field_email.value)) {
        field_email_response.innerHTML = '';

        const formData = new FormData();
        formData.append('field_email', field_email.value);
        formData.append('field_name', field_name.value);
        formData.append('field_project_id', field_project_id.value);
        formData.append('field_company', field_company.value);

        this.state.current_active_option_values.forEach(element => {
          formData.append(element.name.replaceAll(' ', '_').toLowerCase(), element.value);
        });

        if (this.state.base64_image !== '' && this.state.base64_image !== null && this.state.base64_image !== undefined) {
          formData.append('upload', this.dataURIToBlob(this.state.base64_image), 'image.png');
        } else {
          field_email_response.innerHTML = "Error creating the screenshot.";
        }

        const xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
          if (this.readyState == 4 && this.status == 200) {
            if (parseInt(this.responseText) == 0) {
              field_email_response.innerHTML = "Mail sent.";
              return;
            }
            else {
              field_email_response.innerHTML = "There was an error.";
            }
          }
        }
        xhttp.open("POST", 'https://phpstack-134474-1103105.cloudwaysapps.com/thinking_space_mail.php');
        xhttp.send(formData);

        field_email.value = '';
        field_name.value = '';
        field_project_id.value = '';
        field_company.value = '';
        field_email_response.innerHTML = "Sending mail...";
      }
      else {
        field_email_response.innerHTML = "Please check the email address.";
      }

    } catch (err) {
      console.log(err);
      if(field_email_response !== null){
        field_email_response.innerHTML = err;
      }   
    }
  }
}