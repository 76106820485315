
<style>

.app-overlay{
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 2;
    cursor: pointer;
}

.app-overlay.visible{  
    display: block;
    width: 100%;
    height: 100%;  
}

.app-overlay-body{
  background-color: white;
}

.btn.cancel_button{
  margin-right: 16px;
}

.btn.mail_button{
  
}

input{
  width: 100%;
}

</style>

<div class=["app-overlay", state.visible && "visible"]>
  <div class="app-overlay-container container" style="padding-top: 25px;">
    <div class="app-overlay-body row" style="padding-top: 10px;padding-bottom: 10px;" key="body">
      <div.container>
        <h1.left>Email configuration</h1>
          <div.col-12>
            <label for="field_company">Company</label><br>
            <input placeholder="company" type="text" id="field_company" />
          </div>
          <div.col-12>
            <label for="field_name">Name</label><br>
            <input placeholder="name" type="text" id="field_name" />
          </div>
          <div.col-12>
            <label for="field_project_id">Project ID</label><br>
            <input placeholder="project ID" type="text" id="field_project_id" />
          </div>
          <div.col-12>
            <label for="field_email">Email address</label><br>
            <input placeholder="email address" type="email" id="field_email" />
          </div>
          <div.col-12.right>
            <button on-click("handleCancelButtonClick") class="btn cancel_button">Close</button>
            <button class="btn mail_button" on-click("sendConfigurationAsMail")>
              SEND
            </button>
            <div id="field_email_response"></div>
          </div>
        </div>
    </div>
  </div>
</div>

