$ let rootPath = 'https://thinkingspace.360productviewer.com/assets/';


$ const current_active_option_ids = [];
$ const current_active_option_values = [];

<div.main-container.col-12.center>
	<div.item.col-3.col-12-sm>		
		<if(!component.isEmpty(state.product))>			
			<for|option, option_index| of=state.product.options>		
				<if(component.isActiveOption(option, current_active_option_ids))>	
					<div class="option">
						<if(option.type === 'hidden')>	
							<for|option_value, option_values_index| of=state.product.option_values[option.option_id]>	
								<if(component.isActiveOptionValue(option, option_value.option_type_id, current_active_option_ids))>
									$ (option_value.active === 1) ? current_active_option_ids.push(option_value.option_type_id): null;	
									$ (option_value.active === 1) ? current_active_option_values.push({name: option.title, value:option_value.title}): null;
									<input type="hidden" class="product-custom-option" name=`options[${option.option_id}][]` id=`options_${option.option_id}_1` value=`${option_value.option_type_id}`>
								</if>
							</for>
						</if>

						<if(option.type === 'drop_down')>		
							<div.option-title>
								<label>
									${option.title}<span class="required"></span>							
								</label>
							</div>				
							<select onChange("onSelectionChange", option.option_id) name=`options[${option.option_id}]` id=`select_${option.option_id}` class='required-entry' title=`${option.title}`>
								<for|option_value, option_values_index| of=state.product.option_values[option.option_id]>	
									<if(component.isActiveOptionValue(option, option_value.option_type_id, current_active_option_ids))>	
										$ (option_value.active === 1) ? current_active_option_ids.push(option_value.option_type_id): null;	
										$ (option_value.active === 1) ? current_active_option_values.push({name: option.title, value:option_value.title}): null;										
										<option selected=(option_value.active === 1) value=`${option_value.option_type_id}`>${option_value.title}</option>
									</if>
								</for>	
							</select>	
						</if>

						<if(option.type === 'radio')>
							<div.option-title>
								<label>
									${option.title}<span class="required"></span>									
								</label>
							</div>
							<ul id=`options-${option.option_id}-list` class="options-list">
								<for|option_value, option_values_index| of=state.product.option_values[option.option_id]>
									<if(component.isActiveOptionValue(option, option_value.option_type_id, current_active_option_ids))>								
										<li>
											$ (option_value.active === 1) ? current_active_option_ids.push(option_value.option_type_id): null;	
											$ (option_value.active === 1) ? current_active_option_values.push({name: option.title, value:option_value.title}): null;

											<input checked=(option_value.active === 1)
												on-click("onClickChange", option.option_id, option_value.option_type_id)
												type="radio"
												class="radio  validate-one-required-by-name product-custom-option"
												name=`options[${option.option_id}]`
												id=`options_${option.option_id}_${option_values_index}`
												value=`${option_value.option_type_id}`											>

											<span class="label">
												<label for=`options_${option.option_id}_${option_values_index}`>${option_value.title}</label>
											</span>								
										</li>
									</if>
								</for>
							</ul>	
						</if>	

						<if(option.type === 'swatch')>
							<div.option-title>
								<label>
									${option.title}<span class="required"></span>
									$ let hasActiveOption = false;
									$ let first_clickable_option_value = null;
									<for|option_value, option_values_index| of=state.product.option_values[option.option_id]>										
										<if(component.isActiveOptionValue(option, option_value.option_type_id, current_active_option_ids))>
											$ if(first_clickable_option_value === null) {first_clickable_option_value = option_value};
											<if(option_value.active === 1)>
												$ hasActiveOption = true;
												<span class="selected-value">- ${option_value.title}</span>												
											</if>		
										</if>							
									</for>	
									
									$ if(hasActiveOption === false && first_clickable_option_value !== null) {component.onClickChange(option.option_id, first_clickable_option_value.option_type_id, false);}
	
								</label>
							</div>
							<ul id=`ul_swatch_${option.option_id}` class="swatch-list">
								$ let counter = 0;
								<for|option_value, option_values_index| of=state.product.option_values[option.option_id]>	
									<if(component.isActiveOptionValue(option, option_value.option_type_id, current_active_option_ids))>	
										$ (option_value.active === 1) ? current_active_option_ids.push(option_value.option_type_id): null;	
										$ (option_value.active === 1) ? current_active_option_values.push({name: option.title, value:option_value.title}): null;						
										$ let is_active = (option_value.active === 1) ? "checked": "";
										<li on-click("onClickChange", option.option_id, option_value.option_type_id) id=`swatch_${option.option_id}` class=`swatch pointer ${is_active}`>
											<if(option_value.source === 1)>	
												<img src=`${rootPath}${option_value.image_file}` title=`${option_value.title}` alt=`${option_value.title}`>
											</if>	
											<else>
												<a href="#">${option_value.image_file}</a>						
											</else>		
										</li>		
										$ counter++;
									</if>	
								</for>
							</ul>

						</if>	
						
					</div>
				</if>
			</for>		
			<!--
			<div.price>
				${component.calculatePrice(current_active_option_ids)}				
			</div>-->
			<if(state.is_first_call === true)>
				${component.updateViewer(current_active_option_ids)}
			</if>
			<else>
				${component.updateMaterials(current_active_option_ids)}
			</else>
		</if>
	</div>
	<div.item.col-9.col-12-sm style="position: relative;">
		<div.right style="position: absolute; right:0;">
			<if(state.is_first_action === false )>	
				<button class="mail_button" on-click( "toggleOverlay")>
					EMAIL CONFIGURATION
				</button>
			</if>
			<else>
				<button disabled>
					EMAIL CONFIGURATION
				</button>
			</else>
		</div>
		<canvas id=`${state.canvas_id}` touch-action="none">
			<a href="https://360productviewer.com/">3D configurator powered by 360productviewer</a>			
		</canvas>
	
	</div>
</div>

<360productviewer-overlay
	onCancel("handleOverlayCancel")
	onHide("handleOverlayHide")
	key="overlay"  
	visible=state.show_overlay
	current_active_option_values=current_active_option_values
	base64_image=state.base64_image
	width='80%'
	height='80%'
/>


<style>

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
  font-size: 100%;
}

* {
  font-family:  Helvetica, sans-serif;
  line-height: 1.5;
}

h1 {
  font-size: 1.5rem;
}

p {
  font-size: 1.125rem;
  font-weight: 200;
  line-height: 1.8;
}

.font-light {
  font-weight: 300;
}

.font-regular {
  font-weight: 400;
}

.font-heavy {
  font-weight: 700;
}

.left {
  text-align: left;
}

.center-text {
	text-align: center;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.right {
  text-align: right;
}

.justify {
  text-align: justify;
}

.container {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.row {
  position: relative;
  width: 100%;
}

.row [class^="col"] {
  float: left;
  margin: 0.5rem 2%;
  min-height: 0.125rem;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  width: 96%;
}

.col-1-sm {
  width: 4.33%;
}

.col-2-sm {
  width: 12.66%;
}

.col-3-sm {
  width: 21%;
}

.col-4-sm {
  width: 29.33%;
}

.col-5-sm {
  width: 37.66%;
}

.col-6-sm {
  width: 46%;
}

.col-7-sm {
  width: 54.33%;
}

.col-8-sm {
  width: 62.66%;
}

.col-9-sm {
  width: 71%;
}

.col-10-sm {
  width: 79.33%;
}

.col-11-sm {
  width: 87.66%;
}

.col-12-sm {
  width: 100%;
}

.row::after {
	content: "";
	display: table;
	clear: both;
}

.hidden-sm {
  display: none;
}

@media only screen and (min-width: 60em) { /* 960px */

  .col-3 {
    width: 33%;
  }

  .col-9 {
    width: 67%;
  }

  .col-12 {
    width: 100%;
  }

  .hidden-sm {
    display: block;
  }

  .container {
    width: 75%;
    max-width: 60rem;
  }
}

ul{
	list-style: none;
	margin: 0;
	padding: 0;
}

select{
	width: 100%;
	height: 35px;
	border-color: #ddd;
	padding: 0 5px;
	font-size: 17px;
}

.item{
	float:left;
}
.option-title{}

.swatch{
	height: ${state.swatch_height};
	width: ${state.swatch_width};
	border: 2px solid transparent;
}
.swatch.checked{	
	border-color: red;
}
.swatch img{
	width:  100%;
	height:  100%;
}

.swatch-list{
	display:inline-block;
}

.swatch-list li{
	float:left;
}

.option{clear: both; margin-bottom: 10px !important;}
.option ul {width: 100%; height: 100%; padding: 0; }
.option li {list-style: none; margin: 0;}	

#${state.canvas_id} {
	outline: none;
	border: none; 
	width:  100%;
	height:  100%;
}

.option li.swatch {
	margin: 0 !important;		
}

.pointer{cursor: pointer;}

.item.options label {		
	font-size: 17px;
	-moz-user-select: none;
	-ms-user-select: none;
	-webkit-user-select: none;
	-o-user-select: none;
	user-select: none;
	font-weight: 600;
}

label.required:after, span.required:after {
	content: ' *';
	color: #df280a;
	font-weight: normal;  
	font-size: 17px;
}

.selected-value{ font-weight: 100;}

.mail-container{
	position: absolute;
	right: 0px;
}
.mail_button{
	background-color: #df280a;
	color:white;
}

.main-container{
    display: inline-block;
    position: relative;
}

</style>